<template>
  <el-select ref="select" v-model="prop" placeholder="请选择" :style="{ width: '100%' }">
    <el-option hidden :label="option.label" :value="option.value" />
    <el-tree ref="tree" :data="treeData" node-key="value" default-expand-all :expand-on-click-node="false"
      highlight-current check-strictly @node-click="handleNodeClick">
      <span slot-scope="{ node, data }">
        <i v-if="data.children" class="el-icon-folder" />
        <i v-else class="el-icon-document" />
        {{ node.label }}
      </span>
    </el-tree>
  </el-select>
</template>

<script>
export default {
  name: "SelectTree",
  props: {
    propName: String,
    prop: Number,
    treeData: Array,
  },
  watch: {
    prop: {
      immediate: true,
      handler(newValue) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (newValue) {
              this.$refs.tree.setCurrentKey(newValue);
            }
            const data = this.$refs.tree.getCurrentNode() || {
              label: "请选择",
              value: 0,
            };
            this.option.label = data.label;
            this.option.value = data.value;
          }, 500);
        });
      },
    },
  },
  data() {
    return {
      option: { label: "", value: 0 },
    };
  },
  methods: {
    handleNodeClick(data) {
      this.option.label = data.label;
      this.option.value = data.value;
      this.$refs.select.blur();
      this.$emit("handleChangeTreeNode", this.propName, data.value);
    },
  },
};
</script>

<style scoped>
</style>