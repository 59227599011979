import { unAuthRoutes } from "@/router";
import { getRoutes, saveSystemSetting } from "@/api/base";
import { convertRouter } from "@/utils/handleRoutes";

const state = () => ({
  routes: [],
  systemSetting: {},
});

const getters = {
  routes: (state) => state.routes,
  systemSetting: (state) => state.systemSetting,
};

const mutations = {
  setRoutes(state, routes) {
    state.routes = unAuthRoutes.concat(routes);
  },
  setSetting(state, setting) {
    state.systemSetting = setting;
  },
};

const actions = {
  async setRoutes({ commit }) {
    let { data } = await getRoutes();
    data.routes.push({ path: "*", redirect: "/404", hidden: true });
    let accessRoutes = convertRouter(data.routes);
    commit("setRoutes", accessRoutes);
    commit("setSetting", data.systemSetting);
    return accessRoutes;
  },
  async changeSettingItem({ commit }, setting) {
    saveSystemSetting(setting);
  },
};

export default { state, getters, mutations, actions };
