import Vue from "vue";
import { tokenTableName, xbtTokenName } from '@/config'
import { doLogin, doLogout, getToken } from "@/api/user";
import { setItem, getItem, clearAll } from '@/utils/storage';
import { resetRouter } from "@/router";
import { tokenName } from "@/config";
import dayjs from "dayjs";

const state = () => ({
  xbtToken: getItem(xbtTokenName),
  accessToken: getItem(tokenTableName),
  loginRedirect: getItem('login-redirect'),
  permissions: getItem('permissions'),
  lastLoginTime: getItem('last-login-time')
});

const getters = {
  xbtToken: (state) => state.xbtToken,
  accessToken: (state) => state.accessToken,
  loginRedirect: (state) => state.loginRedirect,
  permissions: (state) => state.permissions,
  lastLoginTime: (state) => state.lastLoginTime,
};

const mutations = {
  setXbtToken(state, xbtToken) {
    state.xbtToken = xbtToken;
    setItem(xbtTokenName, xbtToken);
  },
  async setTokenData(state, data) {
    state.accessToken = data.token;
    setItem(tokenTableName, data.token);

    state.loginRedirect = data.redirect;
    setItem('login-redirect', data.redirect);

    state.lastLoginTime = data.token ? dayjs().format('YYYY-MM-DD HH:mm:ss') : null;
    setItem('last-login-time', state.lastLoginTime);
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
    setItem('permissions', permissions);
  },
};

const actions = {
  setPermissions({ commit }, permissions) {
    commit("setPermissions", permissions);
  },
  setXbtToken({ commit }, xbtToken) {
    commit("setXbtToken", xbtToken);
  },
  async setAccessToken({ commit }, query) {
    if (query.token) {
      commit("setTokenData", query);
    }
    const { data } = await getToken();
    if (data) {
      data.redirect = query.redirect;
      commit("setTokenData", data);
    }
  },
  async login({ commit }, userInfo) {
    const { data } = await doLogin(userInfo);
    if (data.token) {
      if (!data.redirect) {
        data.redirect = "home";
      }
      commit("setTokenData", data);
    } else {
      Vue.prototype.$baseMessage(
        `登录接口异常，未正确返回${tokenName}...`,
        "error"
      );
      return;
    }
  },
  async logout({ dispatch }) {
    await doLogout();
    await dispatch("resetAccessToken");
    await resetRouter();
  },
  resetAccessToken({ commit }) {
    commit("setPermissions", []);

    commit("setXbtToken", "");
    commit("setTokenData", {});

    clearAll();
  },
};

export default { state, getters, mutations, actions };
