import Vue from 'vue'
import App from './app'
import store from './store'
import router from './router'
import cookies from 'vue-cookies'
import './filters'
import './plugins'
import '@/views/export'
import './utils/dialogDrag'
import './utils/selectLazyLoad'
import jquery from 'jquery'

Vue.config.productionTip = true;
Vue.use(cookies);
Vue.prototype.$ = jquery;

import Meta from "vue-meta";
Vue.use(Meta);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});
