<template>
  <el-form ref="searchForm" v-if="searchForm && searchForm.items && searchForm.items.length > 0" :model="searchForm.data"
    :inline="true" label-position="right">
    <edit-form-item v-for="(item, index) in searchForm.items" :key="index" :form="searchForm.data" :item="item"
      :class="{ 'collapse-block': showCollapse && !visibleSearchProps.includes(item.prop) }"
      :is-search="true"></edit-form-item>
    <el-form-item>
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="query">
          查询
        </el-button>
        <el-button plain icon="el-icon-refresh-left" @click="resetSearch">
          重置
        </el-button>
        <el-button v-if="showCollapse" type="primary" :icon="isCollapsed ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
          @click="collapse">
          {{ isCollapsed ? "展开" : "收起" }}
        </el-button>
      </el-button-group>
    </el-form-item>
    <el-form-item v-if="buttons.length > 0">
      <button-group :buttons="buttons" @handleClick="handleButtonClick">
      </button-group>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "SearchForm",
  props: {
    searchForm: {
      type: Object,
      require: true,
    },
    buttons: {
      type: Array,
      require: false,
      default() {
        return [];
      },
    },
    showCollapse: {
      type: Boolean,
      default: false,
    },
    visibleSearchProps: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    query() {
      this.$emit("handleQuery");
    },
    resetSearch() {
      this.$refs.searchForm.resetFields();
    },
    collapse() {
      this.isCollapsed = !this.isCollapsed;
      (document.querySelectorAll(".collapse-block") || []).forEach(
        (selector) => {
          selector.style.display = this.isCollapsed ? "none" : "inline-block";
        }
      );
    },
    handleButtonClick(button) {
      this.$emit("handleButtonClick", button);
    }
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  display: inline-block;
  vertical-align: top;

  .el-form-item {
    margin-bottom: 0 !important;
  }
}

.collapse-block {
  display: none;
}
</style>