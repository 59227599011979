<template>
  <el-container class="tree-wrapper">
    <el-header height="30px">
      <div class="tree-title"><i class="el-icon-finished" />{{ treeTitle }}</div>
    </el-header>
    <el-main>
      <el-tree ref="tree" :data="treeData" default-expand-all :expand-on-click-node="false" highlight-current
        check-strictly @node-click="handleNodeClick">
        <span slot-scope="{ node, data }">
          <i v-if="data.children" class="el-icon-folder" />
          <i v-else class="el-icon-document" />
          {{ node.label }}
        </span>
      </el-tree>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: "EditTree",
  props: {
    treeTitle: {
      type: String,
      required: true,
    },
    treeData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleNodeClick(data) {
      this.$emit("handleNodeClick", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.tree-wrapper {
  ::v-deep {
    .el-tree {
      height: 100%;
      overflow: auto;
    }
  }
}
</style>