<template>
  <el-container class="layout-wrapper">
    <el-header height="66px">
      <layout-header :activeRouteID="activeRouteID" @handleMenuClick="matchMenu" />
    </el-header>
    <el-main>
      <el-container class="layout-middle">
        <el-aside v-if="!isHidden" width="auto" class="layout-menu">
          <!-- @mouseover.native="isCollapse = false" @mouseleave.native="isCollapse = true" -->
          <el-menu :collapse-transition="false" mode="vertical" :default-openeds="openedMenus" background-color="#000"
            text-color="#fff" active-text-color="#fff" :default-active="defaultActive" class="sub-menu"
            :collapse="isCollapse">
            <navbar-item v-for="subMenu in activeMenu.children" :key="subMenu.meta.routeID" :collapse="isCollapse"
              :menu="subMenu" :full-path="activeMenu.path" />
          </el-menu>
        </el-aside>
        <el-main id="layout-main" :class="{ 'show-menu': !isHidden }">
          <el-container id="main-wrapper" direction="vertical">
            <el-header height="38px" v-if="false">
              <layout-tabs></layout-tabs>
            </el-header>
            <el-header height="31px" v-if="false">
              <layout-breadcrumb />
            </el-header>
            <el-main id="main-container">
              <layout-main-content />
            </el-main>
          </el-container>
        </el-main>
      </el-container>
    </el-main>
    <el-footer height="40px">
      <layout-footer />
    </el-footer>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { tokenName } from '@/config';
import { isExternal } from '@/utils/validate';
import path from 'path';

export default {
  name: 'Layout',
  mounted() {
    this.matchMenu(this.$router.currentRoute.matched[0].meta.routeID, this.$router.currentRoute.meta.routeID, true);
    this.$nextTick(() => {
      window.addEventListener(
        'storage',
        (e) => {
          if (e.key === tokenName && (e.key === null || e.value === null)) {
            window.location.reload();
          }
        },
        false
      );
    });
  },
  data() {
    return {
      activeRouteID: '',
      defaultActive: '',
      isHidden: true,
      isCollapse: false,
      openedMenus: [],
    };
  },
  computed: {
    ...mapGetters({
      routes: 'routes/routes',
    }),
    activeMenu() {
      let activeMenu = this.routes.filter((route) => route.meta && route.meta.isMenu && !route.meta.isHidden
        && route.meta.routeID == this.activeRouteID);
      if (activeMenu.length > 0) {
        return activeMenu[0];
      }
      return null;
    },
  },
  methods: {
    matchMenu(menuRouteID, subRouteID = null, skipLink = false) {
      if (this.activeRouteID == menuRouteID) {
        return;
      }
      this.activeRouteID = menuRouteID;
      this.defaultActive = '';
      this.isHidden = true;
      if (this.activeMenu.children && this.activeMenu.children.length > 0) {
        this.activeMenu.children.forEach((subMenu) => {
          this.openedMenus.push(subMenu.meta.routeID);
        });
        this.defaultActive = subRouteID ? subRouteID : this.activeMenu.children[0].meta.routeID;
        this.isHidden = this.activeMenu.children.length < 2;
        if (!skipLink) {
          this.handleLink(this.activeMenu.path, this.activeMenu.children[0].path, this.activeMenu.children[0].meta.target);
        }
      }
    },
    async handleLink(fullPath, routePath, target) {
      if (this.$('#layout-main .tabs').is(':hidden')) {
        await this.$store.dispatch('tabsBar/delRoute', this.$route);
      }
      if (target === '_blank') {
        if (isExternal(routePath)) {
          window.open(routePath);
        } else if (isExternal(fullPath)) {
          window.open(fullPath);
        } else if (this.$route.path !== path.resolve(fullPath, routePath)) {
          let routeData = this.$router.resolve(path.resolve(fullPath, routePath));
          window.open(routeData.href);
        }
      } else {
        if (isExternal(routePath)) {
          window.location.href = routePath;
        } else if (isExternal(fullPath)) {
          window.location.href = fullPath;
        } else if (this.$route.path !== path.resolve(fullPath, routePath)) {
          this.$router.push(path.resolve(fullPath, routePath));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;

  &>main,
  &>footer {
    border-top: 0 !important;
  }

  .layout-middle {
    position: relative;

    .layout-menu {
      height: 100%;
      //position: absolute;
      //z-index: 999;
    }

    &>aside {
      padding: 0;
    }

    .sub-menu {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      border: 0;

      &:not(.el-menu--collapse) {
        width: 130px;
      }
    }

    #layout-main {
      background-color: rgba(233, 237, 241, 1) !important;
      border-left: 0 !important;
      padding: 10px;

      &.show-menu {
        // padding-left: 74px;
      }

      #main-wrapper {

        &>main,
        &>footer {
          border-top: 0 !important;
        }

        #main-container {
          padding: 0;
          position: relative;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
