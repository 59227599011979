<template>
    <el-badge :value="menu.children && menu.children.some(t => t.meta && t.meta.isMenu) ? null : menu.meta.badge"
        :max="99"
        :is-dot="(collapse && !menu.children && menu.meta && menu.meta.badge) || menu.children && menu.children.some(t => t.meta && t.meta.badge)">
        <el-submenu v-if="menu.children && menu.children.some(t => t.meta && t.meta.isMenu)" :index="menu.meta.routeID">
            <template slot="title">
                <i v-if="menu.meta.icon" :class="menu.meta.icon" />
                <span v-else-if="collapse">
                    {{ menu.meta.subTitle ? menu.meta.subTitle : menu.meta.title.substring(0, 2) }}
                </span>
                <span v-if="!collapse" slot="title">{{ menu.meta.title }}</span>
            </template>
            <navbar-item v-for="subMenu in menu.children" :key="subMenu.meta.routeID" :collapse="false" :menu="subMenu"
                :full-path="handlePath(menu.path)" />
        </el-submenu>
        <el-menu-item v-else :index="menu.meta.routeID" @click="handleLink">
            <i v-if="menu.meta.icon" :class="menu.meta.icon" />
            <span v-else-if="collapse">
                {{ menu.meta.subTitle ? menu.meta.subTitle : menu.meta.title.substring(0, 2) }}
            </span>
            <span slot="title">{{ menu.meta.title }}</span>
        </el-menu-item>
    </el-badge>
</template>

<script>
import { isExternal } from "@/utils/validate";
import path from "path";

export default {
    name: "NavbarItem",
    props: {
        fullPath: {
            type: String,
            default: ''
        },
        menu: {
            type: Object,
            required: true,
        },
        collapse: {
            type: Boolean
        },
    },
    methods: {
        handlePath(routePath) {
            if (isExternal(routePath)) {
                return routePath;
            }
            if (isExternal(this.fullPath)) {
                return this.fullPath;
            }
            return path.resolve(this.fullPath, routePath);
        },
        async handleLink() {
            const routePath = this.menu.path;
            const target = this.menu.meta.target;
            if (this.$route.path !== path.resolve(this.fullPath, routePath) && this.$('#layout-main .tabs').is(':hidden')) {
                await this.$store.dispatch('tabsBar/delRoute', this.$route);
            }
            if (target === "_blank") {
                if (isExternal(routePath)) {
                    window.open(routePath);
                } else if (isExternal(this.fullPath)) {
                    window.open(this.fullPath);
                } else if (this.$route.path !== path.resolve(this.fullPath, routePath)) {
                    let routeData = this.$router.resolve(path.resolve(this.fullPath, routePath));
                    window.open(routeData.href);
                }
            } else {
                if (isExternal(routePath)) {
                    window.location.href = routePath;
                } else if (isExternal(this.fullPath)) {
                    window.location.href = this.fullPath;
                } else if (this.$route.path !== path.resolve(this.fullPath, routePath)) {
                    this.$router.push(path.resolve(this.fullPath, routePath));
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.el-badge {
    width: 100%;
}

.el-menu-item {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 0px !important;
    min-width: auto;

    &.is-active {
        background: #0075e3 !important;
    }

    ::v-deep {
        .el-tooltip {
            padding: 0 !important;
        }
    }
}

::v-deep {
    .el-badge__content {
        top: 15px;
        right: 30px;
        border-color: #f56c6c;
        font-size: small;
        font-weight: normal;
        padding: 0 2px;

        &.is-dot {
            right: 15px;
            padding: 0;
        }
    }

    .el-submenu__title {
        color: white;
        font-size: 18px;
        font-weight: 600;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding: 0px !important;

        .el-submenu__icon-arrow {
            font-size: 18px;
            font-weight: 600;
            right: 0;
        }
    }

    .el-submenu__title i {
        color: white;
    }

    .el-submenu__title:hover,
    .el-menu-item:hover {
        background: #0075e3 !important;
    }
}
</style>
