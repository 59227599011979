<template>
    <el-dialog title="购买续费" :visible.sync="visible" :close-on-click-modal="false" width="800px">
        <el-form label-width="120px" label-suffix="：">
            <el-form-item label="版本">
                <div class="renewal-option" :class="{ active: packageX == 0 }" @click="packageX = 0">专业版</div>
                <div class="renewal-option" :class="{ active: packageX == 1 }" @click="packageX = 1">旗舰版</div>
            </el-form-item>
            <el-form-item label="鞋捕头档口">
                <div class="renewal-option" :class="{ active: !checkIn }" @click="checkIn = false">未入驻</div>
                <div class="renewal-option" :class="{ active: checkIn }" @click="checkIn = true">已入驻</div>
                <a class="renewal-xiebutou" href="https://www.xiebutou.com/join.html" target="_blank">>>>前往鞋捕头入驻</a>
            </el-form-item>
            <el-form-item label="支付方式">
                <div class="renewal-option" :class="{ active: payMethod == 'alipay' }" @click="payMethod = 'alipay'">支付宝
                </div>
                <div class="renewal-option" :class="{ active: payMethod == 'wxpay' }" @click="payMethod = 'wxpay'">微信支付
                </div>
            </el-form-item>
        </el-form>
        <el-divider />
        <img :src="payUrl" alt="扫码支付" width="300px" height="400px" style="float:right;margin-top: 10px;" />
        <el-form label-width="125px" label-suffix="：" style="margin-top: 20px;">
            <el-form-item label="应付金额">
                <span class="renewal-amount" :class="{ discount: checkIn == 1 }">{{ packageX == 0 ? '2000' : '2599' }} 元
                    / 年</span>
            </el-form-item>
            <el-form-item label="入驻优惠金额" v-if="checkIn == 1">
                <span class="renewal-amount">{{ packageX == 0 ? '799' : '1299' }} 元 / 年</span>
            </el-form-item>
            <el-form-item label="有效期至">
                <span class="renewal-date">{{ expire }}</span>
            </el-form-item>
        </el-form>
        <div style="color:red;">
            <div style="color:black;font-size:18px;font-weight:bold;">注意事项：</div>
            <div>1、根据档口类型进行支付，付费后请联系服务群客服：云果仓，为您开通使用期限</div>
            <div>2、优惠金额仅适用已入驻鞋捕头的档口，非入驻档口需支付版本原价。</div>
        </div>
    </el-dialog>
</template>
<script>
import dayjs from 'dayjs';
export default {
    name: 'renewal',
    data() {
        return {
            visible: false,
            company: null,
            packageX: 1,
            checkIn: false,
            payMethod: 'alipay',
            amount: 2599,
            expire: '',
        };
    },
    computed: {
        payUrl() {
            if (this.packageX == 0) {
                return `/static/pay/${this.payMethod}-${this.checkIn ? '799' : '2000'}.jpg`;
            } else {
                return `/static/pay/${this.payMethod}-${this.checkIn ? '1299' : '2599'}.jpg`;
            }
        },
    },
    methods: {
        open(company) {
            this.company = company;
            this.expire = dayjs(this.company.ExpireTime).add(1, 'year').format('YYYY年MM月DD日');
            this.visible = true;
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep {
    .el-dialog__body {
        height: 600px;
        max-height: 600px;
        background-color: white;
    }

    .el-form-item {
        height: 50px;
    }

    .el-form-item__label {
        color: #1B1B1B;
        font-size: 16px;
        font-weight: 550;
    }
}

.renewal-option {
    text-align: center;
    display: inline-block;
    width: 130px;
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 0.3em;
    border: 1px solid #D6D6D6;
    box-shadow: 2px 2px 2px #D6D6D6;

    &:hover {
        box-shadow: 5px 5px 5px #D6D6D6;
    }

    &.active {
        color: #FF8737;
        border: 1px solid #FF8737;
    }
}

.renewal-amount {
    font-size: 35px;
    font-weight: bold;
    color: #D9001B;

    &.discount {
        text-decoration: line-through;
        color: #D6D6D6;
    }
}

.renewal-date {
    color: #1B1B1B;
    font-size: 16px;
    font-weight: bold;
}
</style>