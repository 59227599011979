<template>
  <el-container id="layout-header">
    <el-aside id="header-left" width="315px">
      <img :src="require('@/assets/img/logo/logo.png')" />
    </el-aside>
    <el-main id="header-nav">
      <el-menu background-color="#0075E3" text-color="#fff" active-text-color="#fff" :default-active="activeRouteID"
        mode="horizontal" @select="handleClick">
        <el-menu-item :index="menu.meta.routeID" v-for="menu in menus" :key="menu.meta.routeID">{{ menu.meta.title
        }}</el-menu-item>
      </el-menu>
    </el-main>
    <el-aside width="200px" v-if="company.LeaveDay != null && company.LeaveDay <= 3">
      <div>
        剩余时间：{{ company.LeaveDay }}天
        <el-button @click="handleRenewal"
          style="margin-left:5px;color:white;background-color:#FF8737;border-color:#FF8737;">立刻付款</el-button>
      </div>
    </el-aside>
    <el-aside width="40px" id="header-bell" v-if="msgVisible">
      <el-badge :is-dot="!loginData.VersionUpgrade.IsRead" class="item">
        <el-button type="text" size="medium" icon="el-icon-bell" title="更新提示" @click="handleShowMsg"></el-button>
      </el-badge>
      <helper-center ref="helperCenter" position="index" :visible="true"></helper-center>
    </el-aside>
    <el-aside width="70px">
      <div>
        <a style="color:white;" @click="handleDownZip">QQ插件</a>
        <!-- <a style="color:white;" @click="gotoDocs()">帮助中心</a> -->
      </div>
    </el-aside>
    <el-aside width="70px">
      <el-popover trigger="hover">
        <img src="/static/img/contact.png" />
        <div style="font-size: 25px; color: #0075E3;text-align: center;">18060081393</div>
        <a style="color:white;" slot="reference">联系我们</a>
      </el-popover>
    </el-aside>
    <el-aside v-if="false" width="150px">
      <el-popover width="330" trigger="hover" :disabled="messages.length == 0">
        <el-table :data="messages" :show-header="false">
          <el-table-column prop="Content" label="内容" width="180"> </el-table-column>
          <el-table-column prop="CreateTime" label="创建时间" width="146"> </el-table-column>
        </el-table>
        <el-badge slot="reference" :value="messages.length > 0 ? messages.length : null" :max="99">
          <i class="el-icon-message-solid"></i>
          <span>消息</span>
        </el-badge>
      </el-popover>
      <el-popover width="330" style="margin-left: 30px;" trigger="hover" :disabled="true">
        <el-table :data="messages" :show-header="false">
          <el-table-column prop="Content" label="内容" width="180"> </el-table-column>
          <el-table-column prop="CreateTime" label="创建时间" width="146"> </el-table-column>
        </el-table>
        <div slot="reference">
          <i class="el-icon-s-shop"></i>
          <span>货源中心</span>
        </div>
      </el-popover>
      <div>
      </div>
    </el-aside>
    <el-aside width="165px" id="header-right">
      <el-menu background-color="#0075E3" text-color="#fff" active-text-color="#fff" mode="horizontal">
        <el-submenu popper-append-to-body index="user">
          <template slot="title">
            <el-avatar v-if="false" size="small"
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>{{ loginData.Mobile }}
          </template>
          <el-menu-item index="password" @click="editPassword"><i class="ri-lock-2-line"></i>修改密码</el-menu-item>
          <el-menu-item index="logout" @click="logout"><i class="ri-logout-circle-line"></i>退出登录</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>
    <password-edit ref="editPassword" v-if="dialogVisible"></password-edit>
    <renewal ref="renewalRef" />

  </el-container>
</template>
<script>
import { submit } from "@/api/base";
import { mapGetters } from 'vuex';
import { getUserInfo } from '@/api/user';
import { getCnTime } from '@/utils/tool';
import PasswordEdit from '@/views/org/employee/components/PasswordEdit.vue';
import Renewal from '@/views/components/layout/renewal.vue';
import HelperCenter from '@/views/stall/trade/components/HelperCenter.vue';
export default {
  name: 'LayoutHeader',
  components: {
    PasswordEdit,
    Renewal,
    HelperCenter
  },
  props: {
    activeRouteID: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      cnTime: '',
      messages: [],
      loginData: {},
      dialogVisible: false,
      company: {
        LeaveDay: 0
      },
      msgVisible: false,
    };
  },
  created() {
    this.$nextTick(async () => {
      this.cnTime = getCnTime();
      const { data } = await getUserInfo();
      this.loginData = { ...data };
      var upgrade = this.loginData.VersionUpgrade;
      if (upgrade != null) {
        this.msgVisible = true;
      }
    });
  },
  async mounted() {
    const { data } = await submit('api/company/setting');
    this.company = data;
  },
  computed: {
    ...mapGetters({
      routes: 'routes/routes',
    }),
    menus() {
      return this.routes.filter((route) => {
        return route.meta && route.meta.isMenu && !route.meta.isHidden;
      });
    },
  },
  methods: {
    gotoDocs() {
      this.$router.push({ path: '/base/documents' });
    },
    handleClick(routeID) {
      this.$emit('handleMenuClick', routeID);
    },
    logout() {
      this.$baseConfirm('您确定要退出' + this.$baseTitle + '吗?', null, async () => {
        await this.$store.dispatch('tabsBar/delAllRoutes');
        await this.$store.dispatch('user/logout');
        this.$router.push('/login');
      });
    },
    editPassword() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.editPassword.init();
      });
    },
    handleRenewal() {
      this.$refs.renewalRef.open(this.company);
    },
    async handleDownZip() {
      var a = document.createElement("a");
      a.href = "/static/plugins/bot/QQ报单.zip";
      a.download = "QQ报单.zip";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async handleShowMsg() {
      if (this.loginData.VersionUpgrade == null) {
        return;
      }
      var data = this.loginData.VersionUpgrade;
      this.$notify({
        title: data.Title,
        message: '<pre style="width:280px;white-space:pre-wrap;">' + data.Content + '</pre><div style="float:right;">' + (data.StartTime ?? data.CreateTime) + '</div>',
        dangerouslyUseHTMLString: true,
        duration: 0,
        position: 'top-right'
      });
      //设置已读
      if (!data.IsRead) {
        await submit('api/user/setNoticeReaded', { ID: data.ID });
        data.IsRead = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#layout-header {
  width: 100%;
  background: $base-color-blue;
  height: $base-header-height;
  position: relative;
  color: rgba(255, 255, 255, 1);

  .el-aside,
  .el-main {
    border-left: 0;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }

  .el-menu {
    border-bottom: 0 !important;
  }

  #header-left {
    justify-content: right !important;

    img {
      // width: 172px;
      height: 40px;
      object-fit: contain;
    }
  }

  #header-nav {
    line-height: $base-header-height;
    // margin-left: 20px;
    align-items: flex-end;

    .el-menu {
      display: contents;
      overflow-x: auto;
    }

    .el-menu-item {
      font-size: 20px;
      font-weight: 600;
      margin: 0 10px;
      border-bottom: 4px solid;
      background-color: #0075e3 !important;
    }
  }

  #header-right {
    line-height: $base-header-height;
    justify-content: right !important;
    align-items: center;

    .el-submenu {

      ::v-deep {
        .el-submenu__title {
          height: 100%;
          font-weight: 400;
          border-bottom: 0;
          background-color: #0075e3 !important;
        }

        i {
          color: #fff;
          font-size: 15px;
        }
      }
    }
  }

  #header-bell {
    margin-right: 10px;

    ::v-deep {
      .el-button--text {
        color: #ffffff !important;
      }
    }
  }
}

.el-menu--popup {
  .el-menu-item {
    line-height: 46px !important;
    height: 46px;
    display: flex;
    justify-content: center;

    i {
      color: #fff;
      margin-right: 5px;
      font-size: 15px;
    }
  }
}
</style>
