<template>
  <el-container class="layout-footer">
    <el-main>
      <span>Copyright ©{{ dateYear }} {{ title }} {{ domian }}</span>
      <el-divider direction="vertical" />
      <a :href="ICPLink" target="_blank">{{ ICP }}</a>
      <el-divider direction="vertical" />
      <a :href="networkSecurityLink" target="_blank">
        <img :src="networkSecurityImg" :title="networkSecurity" />
        {{ networkSecurity }}
      </a>
    </el-main>
  </el-container>
</template>

<script>
import { domain, ICP, ICPLink, NetworkSecurityLink, NetworkSecurityImg, NetworkSecurity } from '@/config';

export default {
  name: 'LayoutFooter',
  data() {
    return {
      dateYear: new Date().getFullYear(),
      title: this.$baseTitle,
      domian: domain,
      ICP: ICP,
      ICPLink: ICPLink,
      networkSecurityLink: NetworkSecurityLink,
      networkSecurityImg: NetworkSecurityImg,
      networkSecurity: NetworkSecurity,
    };
  },
};
</script>

<style lang="scss" scoped>
.layout-footer {
  width: 100%;
  height: $base-footer-height;
  line-height: $base-footer-height;
  background: white;
  text-align: center;
  font-size: 13px;
  font-weight: 400;

  .el-divider {
    background-color: #2c3e50;
  }

  a {
    color: #2c3e50;

    img {
      vertical-align: middle;
      height: 20px;
      width: 20px;
    }
  }
}

.el-main{
  overflow: hidden;
}
</style>
