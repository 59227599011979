import Vue from 'vue';
import { intFormat, floatFormat, getPrecision } from '@/utils/validate';

Vue.filter('intFormat', (value) => {
  return intFormat(value);
});

Vue.filter('decimalFormat', (value, digit = 2) => {
  return floatFormat(value, digit);
});

Vue.filter('dateFormat', (value, format = 'yyyy-MM-dd HH:mm:ss', splitChar = ' ', dateSplitChar = '-', timeSplitChar = ':') => {
  if (!value) {
    return '';
  }
  let datetime = value.split(splitChar);
  let date = datetime.length > 0 ? datetime[0] : '';
  let dateArr = date.split(dateSplitChar);
  let y = dateArr.length > 0 ? dateArr[0] : '';
  let m = dateArr.length > 1 ? dateArr[1] : '';
  let d = dateArr.length > 2 ? dateArr[2] : '';
  let time = datetime.length > 1 ? datetime[1] : '';
  let timeArr = time.split(timeSplitChar);
  let hh = timeArr.length > 0 ? timeArr[0] : '';
  let mm = timeArr.length > 1 ? timeArr[1] : '';
  let ss = timeArr.length > 2 ? timeArr[2] : '';
  return format.replace(/yyyy/g, y).replace(/yy/g, y.substr(-2)).replace(/MM/g, m).replace(/dd/g, d).replace(/HH/g, hh).replace(/mm/g, mm).replace(/ss/g, ss);
});

function inputFormat(val, isFloat, formatValue) {
  if (!val) {
    val = '';
  }
  // 期望保留的最大小数位数
  let precision = 0;
  //是否浮点数
  if (isFloat) {
    precision = getPrecision(formatValue);
  }

  if (typeof val !== 'number') {
    var floatValue = parseFloat(val);
    if (!isNaN(floatValue)) {
      let array = val.split('.');
      if (array.length > 1 && array[1].length > precision) {
        val = floatValue.toFixed(precision);
      }
    }
  }
  val += '';

  //最大值、最小值
  let min = null,
    max = null;
  if (typeof formatValue === 'object') {
    min = parseFloat(formatValue.min);
    max = parseFloat(formatValue.max);
  }

  if (min != null && !isNaN(min) && min >= 0) {
    // 不能是负数
    val = val.replace('-', '');
  }
  if (max != null && !isNaN(max) && max <= 0) {
    val = '-' + val;
  }
  // 清除"数字"和"."以及"-"以外的字符
  val = val.replace(/[^-\d.]/g, '');

  // 只保留第一个'-'号
  val = val
    .replace(/-{2,}/g, '-')
    .replace(/^-0+(\d)/, '-$1')
    .replace(/(\d)-/g, '$1');
  // 只保留一个'.'号
  val = val.replace(/\.{2,}/g, '.');
  //第一位0开头，0后面为数字，则过滤掉，取后面的数字
  val = val.replace(/^0+(\d)/, '$1');
  // 将 '.' 替换成 '0.'
  val = val.replace(/^\./g, '0.');
  // 将 '-.' 替换成 '-0.'
  val = val.replace(/^-\./, '-0.');

  // 增加'-'号的支持
  const str = '^(\\-)*(\\d+)\\.(\\d{' + precision + '}).*$';
  const reg = new RegExp(str);
  if (precision === 0) {
    // 不需要小数点
    val = val.replace(reg, '$1$2');
  } else {
    // 通过正则保留小数点后指定的位数
    val = val.replace(reg, '$1$2.$3');
  }

  if (val !== '') {
    if (min != null && !isNaN(min) && parseFloat(val) < min) {
      val = min;
    }
    if (max != null && !isNaN(max) && parseFloat(val) > max) {
      val = max;
    }
  }
  return val;
}

Vue.directive('format', {
  bind(el, binding, vnode) {
    const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    ele.handler = function () {
      if (binding.modifiers.int || binding.modifiers.float) {
        ele.value = inputFormat(ele.value, binding.modifiers.float ? true : false, binding.value);
      }
      if (vnode.componentInstance) {
        vnode.componentInstance.$emit('input', ele.value);
      } else {
        vnode.elm.dispatchEvent(new CustomEvent('input', ele.value));
      }
    };
    ele.addEventListener('input', ele.handler);
    ele.addEventListener('focus', ele.handler);
  },
  unbind: function (el) {
    el.removeEventListener('input', el.handler);
  },
});
