<template>
  <el-dialog title="修改密码" :visible="visible" :close-on-click-modal="false" width="400px" @close="close" append-to-body>
    <div>
      <el-input :type="showPassword ? 'text' : 'password'" placeholder="至少6个字符且包含数字、字母大小写" prefix-icon="el-icon-lock"
        v-model="password">
        <i slot="suffix" :class="[showPassword ? 'ri-eye-line' : 'ri-eye-close-line']"
          @click="showPassword = !showPassword"></i>
      </el-input>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { submit } from "@/api/base";
import { checkPassword } from "@/utils/validate";

export default {
  name: "PasswordEdit",
  data() {
    return {
      visible: false,
      password: "",
      showPassword: false,
    };
  },
  methods: {
    init() {
      this.password = "";
      this.visible = true;
    },
    async save() {
      if (!checkPassword(this.password)) {
        this.$baseMessage(
          "密码格式不正确，至少6个字符且包含数字、字母大小写",
          "error"
        );
        return;
      }
      await submit("/api/employee/editPassword", { password: this.password });
      this.$baseMessage("保存成功", "success");
      this.close();
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input__suffix-inner {
    i {
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
