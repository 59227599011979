import { storage, storageDomain } from '@/config'

/**
 * @description 获取数据
 * @param name
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getItem(name) {
    let itemName = `${storageDomain}-${name}`;
    switch (storage) {
        case "sessionStorage":
            return sessionStorage.getItem(itemName);
        default:
            return localStorage.getItem(itemName);
    }
}

/**
 * @description 存储数据
 * @param name
 * @param value
 * @returns {void|*}
 */
export function setItem(name, value) {
    let itemName = `${storageDomain}-${name}`;
    switch (storage) {
        case "sessionStorage":
            sessionStorage.setItem(itemName, value);
            break;
        default:
            localStorage.setItem(itemName, value);
            break;
    }
}

/**
 * @description 移除数据
 * @param name
 * @returns {void|Promise<void>}
 */
export function removeItem(name) {
    let itemName = `${storageDomain}-${name}`;
    switch (storage) {
        case "sessionStorage":
            sessionStorage.removeItem(itemName);
            break;
        default:
            localStorage.removeItem(itemName);
            break;
    }
}

/**
 * @description 清空所有数据
 * @returns {void|Promise<void>}
 */
export function clearAll() {
    switch (storage) {
        case "sessionStorage":
            sessionStorage.clear();
            break;
        default:
            localStorage.clear();
            break;
    }
}