export function getCnTime() {
  const hour = new Date().getHours();
  const thisTime = hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好';
  return thisTime;
}

export function getDateTime(addDay = 0) {
  return dayjs().add(addDay, 'day').format('YYYY-MM-DD HH:mm:ss');
}

export function getDate(addDay = 0) {
  return dayjs().add(addDay, 'day').format('YYYY-MM-DD');
}

export function getRandomNum() {
  const date = new Date();
  return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getUTCMilliseconds()}`;
}

export function getColumn(columnName, array) {
  let columnArr = [];
  if (!array || array.length == 0 || !Object.prototype.hasOwnProperty.call(array[0], columnName)) {
    return columnArr;
  }
  for (var item of array) {
    columnArr.push(item[columnName]);
  }
  return columnArr;
}

export function getColumnValue(columnName, array) {
  let columnValue = '';
  if (!array || array.length == 0 || !Object.prototype.hasOwnProperty.call(array[0], columnName)) {
    return columnValue;
  }
  for (var item of array) {
    columnValue += ',' + item[columnName];
  }
  return columnValue.substring(1);
}

import dayjs from "dayjs";

export function getDatePickerOptions() {
  return {
    shortcuts: [{
      text: '今日',
      onClick(picker) {
        const start = dayjs().startOf('day').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '昨日',
      onClick(picker) {
        const start = dayjs().startOf('day').subtract(1, 'day').format('YYYY-MM-DD');
        picker.$emit('pick', [start, start]);
      }
    }, {
      text: '七日内',
      onClick(picker) {
        const start = dayjs().startOf('day').subtract(6, 'day').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '本月',
      onClick(picker) {
        const start = dayjs().startOf('month').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '三个月内',
      onClick(picker) {
        const start = dayjs().subtract(2, 'month').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '半年内',
      onClick(picker) {
        const start = dayjs().subtract(5, 'month').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '今年内',
      onClick(picker) {
        const start = dayjs().startOf('year').format('YYYY-MM-DD');
        const end = dayjs().format('YYYY-MM-DD');
        picker.$emit('pick', [start, end]);
      }
    }]
  }
}

export function getDateTimePickerOptions() {
  return {
    shortcuts: [{
      text: '今日',
      onClick(picker) {
        const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
        picker.$emit('pick', time);
      }
    }, {
      text: '昨日',
      onClick(picker) {
        const time = dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
        picker.$emit('pick', time);
      }
    }, {
      text: '一周前',
      onClick(picker) {
        const time = dayjs().subtract(6, 'day').format('YYYY-MM-DD HH:mm:ss');
        picker.$emit('pick', time);
      }
    }]
  }
}
