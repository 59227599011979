<template>
    <el-select ref="select" v-model="changValue" placeholder="请选择档口" :style="select_style ? select_style : 'width:100%;'"
        :disabled="disabled ? disabled : false" :clearable="true" @change="handleChanged">
        <el-option v-for="item in (options || [])" :key="item.ID" :label="item.Name" :value="item.ID">
            <span class="span_label_left">{{ item.Name }}</span>
            <span class="span_label_right"></span>
        </el-option>
    </el-select>
</template>

<script>
import { submit } from "@/api/base";
export default {
    name: "StoreSelect",
    props: {
        value: {},
        select_style: null,
        disabled: null,
        set_default: null,
    },
    data() {
        return {
            options: [],
            defaultValue: null,
        };
    },
    computed: {
        changValue: {
            get() {
                var that = this;
                let val = that.value;
                if (typeof (val) == 'undefined' || val <= 0) {
                    let setDefault = that.set_default ? that.set_default : false;
                    if (setDefault) {
                        val = that.getDefaultVal();
                        if (val != undefined) {
                            this.$emit('update:value', val);
                        }
                    } else {
                        val = undefined;
                    }
                }
                return val;
            },
            set(value) {
                this.$emit('update:value', value);
            }
        }
    },
    mounted() {
        var that = this;
        that.$nextTick(async () => {
            that.getOptions();
        });
    },
    methods: {
        async getOptions() {
            var that = this;
            const { data } = await submit("/api/common/getStoreInfoList");
            if (typeof (data) != 'undefined' && data.length > 0) {
                that.options = data;
            }
        },
        getDefaultVal() {
            return this.options.length > 0? this.options[0].ID: undefined;
        },
        handleChanged(id) {
            var that = this;
            if (that.$listeners['handleChanged']) {
                var row = null;
                for (let i = 0; i < that.options.length; i++) {
                    let item = that.options[i];
                    let val = typeof (id) == 'undefined' ? 0 : id;
                    if (item.WID == val) {
                        row = item;
                        break;
                    }
                }
                this.$emit("handleChanged", row);
            }
        },
    }
}
</script>
<style scoped>
.span_label_left {
    float: left;
}

.span_label_right {
    float: right;
    color: #8492a6;
    font-size: 13px;
    margin-left: 20px;
}
</style>