import request from '@/utils/request'
import { encrypt } from '@/utils/rsaEncrypt';
import { submit } from './base';

export function getPublicKey() {
  return request({
    url: '/api/user/getPublicKey'
  })
}

export async function doLogin(data) {
  data.password = await encrypt(data.password);
  return request({
    url: '/api/user/login',
    data
  })
}

export function doRegister(data) {
  data.password = encrypt(data.password);
  return request({
    url: '/api/user/register',
    data
  })
}

export async function getToken() {
  return request({ url: '/api/user/getToken' })
}

export function getUserInfo() {
  return submit('/api/user/getUserInfo')
}

export function doLogout() {
  return request({
    url: '/api/user/loginOut'
  })
}

export function getXiebutouToken() {
  return submit('/api/token/getXiebutouToken')
}