import router from '@/router'
import store from '@/store'
import { getPageConfig } from "@/api/base";
import { getItem, setItem } from "@/utils/storage";
import {
    recordRoute,
    routesWhiteList
} from '@/config'

router.beforeResolve(async (to, from, next) => {
    if (routesWhiteList.indexOf(to.path) !== -1) {
        next();
    }
    else if (store.getters['user/accessToken']) {
        if (to.meta && to.meta.routeID) {
            const permissions = store.getters['user/permissions'];
            if (permissions.length > 0) {
                if (store.getters['user/permissions'].some(t => t == to.meta.routeID)) {
                    let pageConfig = {};
                    let itemName = `route-${to.meta.routeTitle}(${to.meta.routeID})`;
                    let pageConfigJson = getItem(itemName);
                    if (pageConfigJson == typeof undefined || pageConfigJson == null) {
                        const { data } = await getPageConfig({
                            routeID: to.meta.routeID,
                        });
                        pageConfig = data;
                        setItem(itemName, JSON.stringify(pageConfig));
                    } else {
                        pageConfig = JSON.parse(pageConfigJson);
                    }
                    to.meta["pageConfig"] = pageConfig;
                    handleKeepAlive(to);
                    next();
                }
                else {
                    next({ path: '/404' })
                }
                return;
            }
        }
        try {
            let accessRoutes = await store.dispatch('routes/setRoutes');
            let routeIDs = [];
            let loginRedirect = { name: store.getters['user/loginRedirect'] };
            let hasRoute = false;
            accessRoutes.forEach((item) => {
                router.addRoute(item);
                if (item.children) {
                    item.children.forEach(c => {
                        if (c.name == loginRedirect.name) {
                            hasRoute = true;
                        }
                        routeIDs.push(c.meta.routeID);
                    });
                }
            });
            if (!hasRoute) {
                loginRedirect = { path: '/404' };
            }
            await store.dispatch('user/setPermissions', routeIDs);
            next(to.path && to.path != '/' ? to.path : loginRedirect);
        } catch {
            await store.dispatch('user/resetAccessToken')
        }
    }
    else {
        next(recordRoute ? `/login?redirect=${to.path}` : '/login');
    }
    //document.title = getPageTitle(to.meta.title);
});

function handleKeepAlive(to) {
    if (to.matched && to.matched.length > 2) {
        for (let i = 0; i < to.matched.length; i++) {
            const element = to.matched[i];
            if (element.components.default.name === "EmptyLayout") {
                to.matched.splice(i, 1);
                i--;
            }
        }
    }
}

router.afterEach(() => {
    if (process.env.NODE_ENV === 'production') {
        let VUE_APP_VERSION = require('../../package.json').version;
        let vers = getItem("vueVersion");
        if (!vers || VUE_APP_VERSION != vers) {
            setItem("vueVersion", VUE_APP_VERSION);
            location.reload();
        }
    }
})