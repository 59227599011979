import Vue from "vue";
import axios from "axios";
import {
  baseURL,
  contentType,
  method,
  invalidCode,
  noPermissionCode,
  requestTimeout,
  successCode,
  tokenName,
  fileContentType,
} from "@/config";
import store from "@/store";
import qs from "qs";
import router from "@/router";
import { isArray } from "@/utils/validate";

const instance = axios.create({
  baseURL,
  method: method,
  timeout: requestTimeout,
  headers: {
    "Content-Type": contentType,
  },
});

instance.interceptors.request.use(
  (config) => {
    if (config.header) {
      Object.keys(config.header).forEach(item => {
        config.headers[item] = config.header[item];
      });
    }
    if (store.getters["user/accessToken"] && !config.flag) {
      config.headers[tokenName] = "Bearer " + store.getters["user/accessToken"];
    }
    if (
      config.data &&
      config.headers["Content-Type"] ===
        "application/x-www-form-urlencoded;charset=UTF-8"
    ) {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const { data, config } = response;
    
    if (
      response.headers &&
      "content-type" in response.headers &&
      fileContentType.includes(response.headers["content-type"])
    ) {
      let fileName = "";
      if (response.headers && "content-disposition" in response.headers) {
        const dispositions = response.headers["content-disposition"].split("=");
        fileName =
          dispositions.length > 1
            ? dispositions[1].slice(0, dispositions[1].lastIndexOf(";"))
            : "";
      }
      return {
        code: 200,
        msg: "",
        data: { FileName: decodeURI(fileName), Blob: data },
      };
    }
    const { code, msg } = data;
    const codeVerificationArray = isArray(successCode)
      ? [...successCode]
      : [...[successCode]];
    if (codeVerificationArray.includes(code)) {
      return data;
    }
    handleCode(code, msg);
    return Promise.reject(
      `请求执行失败：${JSON.stringify({ url: config.url, code, msg })}`
    );
  },
  async (error) => {
    const { response, message } = error;
    
    if (response.status == 401) {
      await store.dispatch("tabsBar/delAllRoutes");
      await store.dispatch("user/logout");
      router.push({ path: "/login" }).catch(() => {});
      return Promise.reject(error);
    }
    if (response && response.data) {
      const { status, data } = response;
      handleCode(status, data.msg || message);
      return Promise.reject(error);
    } else {
      let { message } = error;
      if (message === "Network Error") {
        message = "接口连接异常";
      }
      if (message.includes("timeout")) {
        message = "接口请求超时";
      }
      if (message.includes("Request failed with status code")) {
        const code = message.substr(message.length - 3);
        message = `接口${code}异常`;
      }
      Vue.prototype.$baseMessage(message || "后端接口未知异常", "error");
      return Promise.reject(error);
    }
  }
);

const handleCode = (code, msg) => {
  switch (code) {
    case invalidCode:
      Vue.prototype.$baseMessage(msg || `接口${code}异常`, "error");
      store.dispatch("user/resetAccessToken").catch(() => {});
      location.reload();
      break;
    case noPermissionCode:
      router.push({ path: "/401" }).catch(() => {});
      break;
    default:
      Vue.prototype.$baseMessage(msg || `接口${code}异常`, "error");
      break;
  }
};

export default instance;
