<template>
    <el-switch v-if="type == 'switch'" v-model="changValue" :active-text="label" />
    <el-checkbox v-else-if="type == 'checkbox'" v-model="changValue">{{ label }}</el-checkbox>
    <span v-else>
        {{ label }}：
        <el-select v-if="type == 'select'" v-model="changValue">
            <el-option v-for="item in (options || [])" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-radio-group v-if="type == 'redio'" v-model="changValue">
            <el-radio v-for="item in (options || [])" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
        <el-checkbox-group v-if="type == 'checkbox-group'" v-model="changValue">
            <el-checkbox v-for="item in (options || [])" :key="item.value" :label="item.label"
                :value="item.value"></el-checkbox>
        </el-checkbox-group>
        <el-input v-if="type == 'input'" v-model="changValue"></el-input>
    </span>
</template>

<script>
import { submit } from "@/api/base";
export default {
    name: "CustomerParameter",
    props: {
        label: { type: String },
        paramKey: { type: String },
        type: { type: String },
        options: { type: Array },
        value: {},
        warnText: { type: String }
    },
    computed: {
        changValue: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.value == null) {
                    return;
                }
                if (this.value != value) {
                    if (this.warnText && !value) {
                        this.$baseConfirm(this.warnText, null, async () => {
                            this.$emit('update:value', value);
                            this.update(value);
                        });
                    }
                    else {
                        this.$emit('update:value', value);
                        this.update(value);
                    }
                }
            }
        }
    },
    methods: {
        async get() {
            const { data } = await submit('/api/common/getCustomerParameter?key=' + this.paramKey);
            let value = data;
            switch (this.type) {
                case 'checkbox':
                case 'switch':
                    value = value.toLowerCase() == "true" ? true : false;
                    break;
                case 'checkbox-group':
                    value = value.split(',');
                    break;
            }
            this.$emit("update:value", value);
        },
        async update(value) {
            let valueStr;
            if (typeof (value) == Array) {
                valueStr = value.join(',');
            }
            else {
                valueStr = String(value);
            }
            await submit('/api/common/updateCustomerParameter', {
                label: this.label,
                key: this.paramKey,
                value: valueStr
            });
        },
    }
}
</script>