<template>
  <div class="button-group">
    <span v-for="(button, index) in buttons" :key="button.label" @click="
      (!button.children || button.children.length == 0) && handleClick(button)
      ">
      <span class="button">
        <span v-if="!button.children || button.children.length == 0">
          <i :class="[button.icon, 'button-icon']" :style="{ color: button.color }"></i>
          <span class="button-text">{{ button.label }}</span></span>
        <el-dropdown v-else placement="bottom" @command="handleCommand">
          <span>
            <i :class="[button.icon, 'button-icon']" :style="{ color: button.color }"></i>
            {{ button.label }}
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="buttonItem in button.children" :key="buttonItem.label"
              :command="beforeHandleCommand(buttonItem)">
              <i :class="buttonItem.icon" :style="{ color: buttonItem.color }"></i>
              {{ buttonItem.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
      <el-divider v-if="index < buttons.length - 1" direction="vertical" class="divider"></el-divider>
    </span>
  </div>
</template>
<script>
export default {
  name: "ButtonGroup",
  data() {
    return {};
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick(button) {
      this.$emit("handleClick", button);
    },
    beforeHandleCommand(button) {
      return {
        button: button,
      };
    },
    handleCommand(command) {
      this.handleClick(command.button);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-group {
  .button {
    display: inline-block;
    margin-right: 5px;
    padding: 0px 5px;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;

    .el-icon-arrow-down {
      margin-left: 4px;
    }

    .button-icon {
      font-size: 16px;
      vertical-align: middle;
    }

    .button-text {
      vertical-align: middle;
    }

    &:hover {
      border: 1px solid #ecf5ff;
      border-radius: 2px;
      background: #ecf5ff;
      vertical-align: middle;
    }
  }

  .divider {
    margin: 0 10px 0 5px;
  }
}
</style>