import request from "@/utils/request";
import store from "@/store";
import dayjs from "dayjs";

function refreshToken() {
  if (
    store.getters["user/lastLoginTime"] &&
    dayjs(store.getters["user/lastLoginTime"]).isBefore(
      dayjs().subtract(1, "day")
    )
  ) {
    store.commit("user/setAccessToken");
  }
}

export function submit(url, data, flag, header) {
  refreshToken();
  return request({
    url,
    data: data || {},
    header: header || null,
    flag: flag || false,
  });
}

export function getRoutes() {
  return submit("/api/config/getRouteConfig");
}

export function getPageConfig(data) {
  return submit("/api/config/getPageConfig", data || {});
}

export function saveSystemSetting(setting) {
  if (!setting) return;
  return submit("/api/stall/saveSystemSetting", setting);
}

export function exportFile(url, data) {
  refreshToken();
  return request({
    url,
    data: data || {},
    responseType: "blob",
  });
}
