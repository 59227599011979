<template>
  <div :class="defaultCss + ' ' + position" v-if="false">
    <el-button type="text" size="medium" icon="el-icon-question" @click="handleHelper()">帮助中心</el-button>
  </div>
</template>
<script>
import { submit } from "@/api/base";
export default {
  name: "HelperCenter",
  props: {
    position: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      defaultCss: 'div-help-right',
    };
  },
  methods: {
    async handleHelper() {
      if (this.$listeners['handleCallback']) {
        this.$emit("handleCallback");
      } else {
        //this.$router.push({ path: "/base/documents"});
        window.open('/#/base/documents');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.div-help-right {
  position: absolute;
}

.cashier {
  left: 10px !important;
  bottom: 4px !important;
}

.qq {
  right: 70px !important;
  top: 12px !important;
}

.preorder {
  right: 10px !important;
  top: 5px !important;
}

.allocation {
  top: 5px !important;
  left: 500px !important;
}

.product {
  top: 5px !important;
  right: 260px !important;
}

.trade_data {
  top: 5px !important;
  right: 300px !important;
}

.index {
  position:relative !important;
  ::v-deep {
    .el-button--text {
      color: #ffffff !important;
    }

  }
}
</style>
