<template>
  <el-form-item v-if="!item.isHidden" :label="item.label" :prop="item.prop"
    :style="{ width: isSearch ? 'auto' : item.width }" :class="{ 'checkbox-inner': item.type == 'checkbox-inner' }">
    <div :style="{ display: 'inline-block' }">
      <template slot-scope="">
        <el-input v-if="item.type == 'label' ||
          item.type == 'input' ||
          item.type == 'textarea' ||
          item.type == 'password'
          " :disabled="item.type == 'label'"
          :type="item.type == 'textarea' ? 'textarea' : item.type == 'password' ? 'password' : 'text'"
          :rows="item.type == 'textarea' ? 4 : 1" v-model.trim="form[item.prop]" :style="{ width: width }">
        </el-input>
        <el-input v-if="item.type == 'input-decimal'" v-model="form[item.prop]" v-format.float :style="{ width: width }">
        </el-input>
        <el-input v-if="item.type == 'input-decimal(3)'" v-model="form[item.prop]" v-format.float="3"
          :style="{ width: width }">
        </el-input>
        <el-input-number v-if="item.type == 'input-int' || item.type == 'input-number'" v-model="form[item.prop]"
          controls-position="right" :precision="item.type == 'input-int' ? 0 : 3" :min="item.min ? item.min : 0"
          :max="item.max ? item.max : 9999999" :style="{ width: width }"></el-input-number>
        <el-select v-if="item.type == 'select' || item.type == 'select-multi'" v-model="form[item.prop]"
          :disabled="item.disabled" :multiple="item.type == 'select-multi'" :collapse-tags="item.type == 'select-multi'"
          :clearable="!item.isRequired" filterable placeholder="请选择" :style="{ width: width }">
          <el-option v-for="(option, optionIndex) in itemOptions" :key="optionIndex" :label="option.label"
            :value="option.value" :disabled="option.disabled"></el-option>
        </el-select>
        <select-tree v-if="item.type == 'select-tree'" :propName="item.prop" :prop="form[item.prop]"
          :treeData="itemOptions" @handleChangeTreeNode="changeTreeNode" :style="{ width: width }"></select-tree>
        <el-switch v-if="item.type == 'switch'" v-model="form[item.prop]" active-color="#13ce66" inactive-color="#dcdfe6">
        </el-switch>
        <el-switch v-if="item.type == 'switch-string'" v-model="form[item.prop]" active-value="1" inactive-value="0"
          active-color="#13ce66" inactive-color="#dcdfe6">
        </el-switch>
        <el-switch v-if="item.type == 'switch-int'" v-model="form[item.prop]" :active-value="1" :inactive-value="0"
          active-color="#13ce66" inactive-color="#dcdfe6">
        </el-switch>
        <el-cascader v-if="item.type == 'cascader'" v-model="form[item.prop]" :options="itemOptions" filterable clearable
          collapse-tags :props="{ checkStrictly: item.isCheckStrictly, multiple: item.isMultiple, }"
          :show-all-levels="item.isShowAllLevels" :style="{ width: width }"></el-cascader>
        <el-checkbox v-if="item.type == 'checkbox'" v-model="form[item.prop]">
          {{ item.label }}
        </el-checkbox>
        <el-checkbox v-if="item.type == 'checkbox-string'" v-model="form[item.prop]" true-label="1" false-label="0">
          {{ item.label }}
        </el-checkbox>
        <el-checkbox v-if="item.type == 'checkbox-int'" v-model="form[item.prop]" :true-label="1" :false-label="0">
          {{ item.label }}
        </el-checkbox>
        <el-checkbox v-if="item.type == 'checkbox-inner'" v-model="form[item.prop]"
          :style="{ position: 'relative', left: '-30px', width: '0', }">
        </el-checkbox>
        <el-checkbox v-if="item.type == 'checkbox-inner-string'" v-model="form[item.prop]" true-label="1" false-label="0"
          :style="{ position: 'relative', left: '-30px', width: '0', }">
        </el-checkbox>
        <el-checkbox v-if="item.type == 'checkbox-inner-int'" v-model="form[item.prop]" :true-label="1" :false-label="0"
          :style="{ position: 'relative', left: '-30px', width: '0', }">
        </el-checkbox>
        <el-radio v-if="item.type == 'radio'" v-model="form[item.prop]" :label="item.defaultValue">
          {{ item.label }}
        </el-radio>
        <el-date-picker v-if="item.type.indexOf('date') > -1" v-model="form[item.prop]" :type="item.type"
          start-placeholder="开始日期" end-placeholder="结束日期" :placeholder="(item.prop.toLowerCase().indexOf('start') > -1 || item.prop.toLowerCase().indexOf('begin') > -1)
            ? '开始时间' : (item.prop.toLowerCase().indexOf('end') > -1 ? '结束时间' : '选择日期时间')
            " :clearable="false" :style="{ width: width }">
        </el-date-picker>
        <span v-if="item.type == 'img'">
          <img :src="form[item.prop] ? form[item.prop] : noImgUrl"
            :style="{ width: '80px', height: '80px', border: '1px solid #ededed', }" />
          <el-upload :action="uploadUrl" :headers="headers" accept="image/*" :ref="item.prop" :show-file-list="false"
            :on-success="(response, file, fileList) =>
              uploadSuccess(response, file, fileList, item.prop)
              " :on-error="uploadImgError">
            <el-button size="small" type="primary" :style="{ width: '80px' }">上传</el-button>
          </el-upload>
        </span>
        <ueditor-wrap v-if="item.type == 'editor'" v-model="form[item.prop]"
          :config="{ initialFrameWidth: width }"></ueditor-wrap>
      </template>
    </div>
  </el-form-item>
</template>

<script>
import { baseURL, noImgUrl, defaultUploadUrl, successCode, tokenName } from "@/config";

export default {
  name: "EditFormItem",
  props: {
    isSearch: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      require: true,
    },
    labelWidth: {
      type: String,
      default: "100px",
    },
    tipWidth: {
      type: String,
      default: "60px",
    },
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      noImgUrl,
      headers: {
        Authorization: "Bearer " + this.$store.getters["user/accessToken"],
      },
    };
  },
  computed: {
    width() {
      if (this.isSearch) {
        return this.item.width;
      }
      else {
        return `calc(${this.item.width} - 22px - ${this.labelWidth} - ${this.tipWidth})`;
      }
    },
    itemOptions() {
      let itemOptions = [];
      if (this.item.optionJson) {
        try {
          itemOptions = JSON.parse(this.item.optionJson);
        } catch (ex) {
          console.log(ex);
          itemOptions = [];
        }
      } else if (this.item.options) {
        itemOptions = this.item.options;
      }
      return itemOptions;
    },
    uploadUrl() {
      return (
        baseURL + (this.item.uploadUrl || defaultUploadUrl)
      );
    },
  },
  methods: {
    changeTreeNode(prop, value) {
      this.form[prop] = value;
    },
    uploadSuccess(response, file, fileList, prop) {
      if (successCode.includes(response.code)) {
        this.form[prop] = response.data;
      } else {
        this.$baseMessage("上传失败，" + response.msg, "error");
      }
      this.$refs[prop].clearFiles();
    },
    uploadImgError() {
      this.$baseMessage("上传失败", "error");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-inner {
  margin-right: 0;
}
</style>
