export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg);
}

export function isString(str) {
    return typeof str === 'string' || str instanceof String;
}

export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

export function checkMobile(mobile) {
    return /^(0|86|17951)?(1[3-9])[0-9]{9}$/.test(mobile);
}

export function checkPassword(password) {
    return /^(?![\da-z]+$)(?![\dA-Z]+$)(?![\d!#$%^&*@-_]+$)(?![a-zA-Z]+$)(?![a-z!#$%^&*@-_]+$)(?![A-Z!#$%^&*@-_]+$)[\da-zA-z!#$%^&*@-_]{6,}$/.test(password)
}

//获取小数点后位数
export function getPrecision(formatValue) {
    let precision = 2;
    if (typeof formatValue !== 'undefined') {
        if (typeof formatValue === 'string' || typeof formatValue === 'number') {
            precision = parseInt(formatValue);
        } else if (typeof formatValue === 'object' && formatValue.precision) {
            // 支持新的小数点保留位配置
            precision = formatValue.precision;
        }
        if (!Number.isInteger(precision) || precision < 0) {
            precision = 2;
        }
    }
    return precision;
}

export function intFormat(val) {
    if (!val) {
        val = '';
    }
    if (typeof val !== "number") {
        val = parseInt(val);
        if (isNaN(val)) {
            val = 0;
        }
    }
    return val;
}

export function floatFormat(val, formatValue = 2) {
    if (!val) {
        val = '';
    }
    // 期望保留的最大小数位数
    let precision = getPrecision(formatValue);

    if (typeof val !== "number") {
        val = parseFloat(val);
        if (isNaN(val)) {
            val = 0;
        }
    }
    return parseFloat(val.toFixed(precision));
}